import Navigation from "../../Navigation";
import Options from "./Options";

function History() {
  return (
    <>
      <Navigation />
      <Options />
    </>
  );
}

export default History;
