import axios from "axios";

interface LoginType {
  key: string;
}
const useLoginApi = () => {
  const loginApi = async (body: LoginType) => {
    const url = `/API/user/login`;
    const token = "token";

    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.data) {
        if (response.data.success) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  return { loginApi };
};

export default useLoginApi;
