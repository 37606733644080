import React, { useState } from "react";
import { Modal, Button, Dropdown, Input, Menu } from "antd";
import {
  DownOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { statusItems } from "../../../Data";
import { AddStatusFormProps } from "../StatusTable/Types";
import { useFormik } from "formik";
import * as Yup from "yup";
import useStatusApi from "../../../Api/StatusApi";
import useNotification from "../../../Templates/Alert";
import useLoading from "../../../Templates/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserData } from "../../../Utils";

const { TextArea } = Input;
const { name } = getUserData();
const AddStatusForm: React.FC<AddStatusFormProps> = ({
  handleCancel,
  botOptions,
  onReload,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBot, setSelectedBot] = useState<string | undefined>(
    "Select Bot"
  );
  const [selectedStatus, setSelectedstatus] = useState<string | undefined>(
    "Select Status"
  );
  const { openNotification } = useNotification();
  const { showLoading, hideLoading } = useLoading();
  const { id } = getUserData();
  const { addStatusApi } = useStatusApi();

  const location = useLocation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      comment: "",
      status: "",
      bot: "",
      tester: id,
    },
    validationSchema: Yup.object({
      comment: Yup.string()
        .min(100, "Comment must be at least 100 characters")
        .required("Comment is required"),
      status: Yup.string().required("Status is required"),
      bot: Yup.string().required("Bot is required"),
    }),

    onSubmit: async (values) => {
      if ((location.pathname = "/history")) {
        navigate("/status");
      }
      showLoading();
      const response = await addStatusApi(values);
      setTimeout(() => {
        hideLoading();
        if (response.success) openNotification(response.message, "", "success");
        else if (response.exist)
          openNotification(response.message, "", "warning");
        else openNotification(response.message, "", "error");
      }, 1000);
      if (response) onReload();
      handleCancel();
    },
  });

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const handleStatusMenuSelect = ({ key }: any) => {
    const selectedItem = statusItems.find((item) => item.key === key)?.label;
    setSelectedstatus(selectedItem);
    formik.setFieldValue("status", selectedItem || "");
  };

  const handleBotsMenuSelect = ({ key }: any) => {
    const selectedLabel = botOptions.find((item) => item.key === key)?.label;
    setSelectedBot(selectedLabel);
    formik.setFieldValue("bot", key || "");
  };

  const filteredBots = botOptions.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal
      centered
      open={true}
      closeIcon={
        <CloseCircleOutlined
          style={{ fontSize: "25px" }}
          onClick={handleCancel}
        />
      }
      width={700}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0px 0px 0px",
          }}
        >
          <span style={{ fontSize: "14px" }}>{`Notifying as ${name}`}</span>
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{
              color: "#1677ff",
              border: "1px solid #1677ff",
              background: "white",
            }}
          >
            Submit
          </Button>
        </div>
      }
    >
      <span style={{ fontSize: "20px", fontWeight: 500 }}>Add Status</span>

      <div
        style={{
          display: "flex",
          gap: "10px",
          paddingTop: "10px",
          width: "100%",
        }}
      >
        <span style={{ width: "50%" }}>
          <Dropdown
            overlay={
              <span>
                <Input
                  prefix={<SearchOutlined />}
                  placeholder="Search Bot"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Menu
                  // onClick={handleBotsMenuSelect}
                  style={{ maxHeight: "165px", overflowY: "auto" }}
                >
                  {filteredBots.length > 0 ? (
                    filteredBots.map((item) => (
                      <Menu.Item
                        key={item.key}
                        onClick={() => handleBotsMenuSelect(item)}
                        style={{
                          background:
                            formik.values.bot === item.label
                              ? "rgb(0, 127, 255)"
                              : "white",
                          color:
                            formik.values.bot === item.label
                              ? "white"
                              : "black",
                        }}
                      >
                        <div style={{ padding: "5px 5px" }}>
                          {item.label.toUpperCase()}
                        </div>
                      </Menu.Item>
                    ))
                  ) : (
                    <span style={{ display: "flex", justifyContent: "center" }}>
                      Not Found !
                    </span>
                  )}
                </Menu>
              </span>
            }
            placement="bottom"
            trigger={["click"]}
          >
            <Button
              icon={<DownOutlined />}
              style={{
                width: "100%",
                color: formik.values.bot ? "black" : "gray",
                fontSize: "16px",
                fontWeight: 400,
                justifyContent: "left",
              }}
            >
              {selectedBot}
            </Button>
          </Dropdown>

          {formik.touched.bot && formik.errors.bot ? (
            <div className="errorMsg">{formik.errors.bot}</div>
          ) : null}
        </span>
        <span style={{ width: "50%" }}>
          <Dropdown
            overlay={
              <Menu onClick={handleStatusMenuSelect}>
                {statusItems.slice(1).map((item) => (
                  <Menu.Item key={item.key}>
                    <div style={{ padding: "5px 5px" }}>{item.label}</div>
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottom"
            trigger={["click"]}
          >
            <Button
              icon={<DownOutlined />}
              style={{
                width: "100%",
                color: formik.values.status ? "black" : "gray",
                fontSize: "16px",
                fontWeight: 400,
                justifyContent: "left",
              }}
            >
              {selectedStatus}
            </Button>
          </Dropdown>
          {formik.touched.status && formik.errors.status ? (
            <div className="errorMsg">{formik.errors.status}</div>
          ) : null}
        </span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <TextArea
          showCount
          maxLength={3000}
          name="comment"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.comment}
          placeholder={`Add your comments here...\n \nNote: If the bot is currently offline, please indicate who has been informed to investigate the issue. If the bot is operational, describe the steps taken to confirm its functionality. In case of partial functionality, specify what aspects are working and what are not. If the bot is undergoing maintenance, provide details on the ongoing tasks and the expected time for completion.`}
          style={{
            height: 200,
            resize: "none",
            marginTop: "10px",
            fontSize: "16px",
          }}
        />
        {formik.touched.comment && formik.errors.comment ? (
          <div className="errorMsg">{formik.errors.comment}</div>
        ) : null}
      </form>
    </Modal>
  );
};

export default AddStatusForm;
