import { useState } from "react";
import axios from "axios";
import {
  StatusDataType,
  addStatusApiType,
} from "../Components/Status/StatusTable/Types";
import { getUserData } from "../Utils";

const useStatusApi = () => {
  const [statsData, setStatsData] = useState<StatusDataType[]>([]);
  const [statsCountData, setStatsCountData] = useState<any>();
  const { token } = getUserData();
  const [loading, setLoading] = useState(false);

  const getStatsApi = async () => {
    const url = `/API/getStats`;
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data) {
        if (response.data.length > 0) setStatsData(response.data);
        setLoading(false);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      setStatsData([]);
    }
  };

  const addStatusApi = async (data: addStatusApiType) => {
    const url = `/API/addStatus`;
    setLoading(true);
    const body = data;
    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };
    try {
      const response = await axios.post(url, body, { headers });
      if (response.data) {
        setLoading(false);

        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getStatsCountApi = async () => {
    const url = `/API/bot/getCount`;
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data) {
        setStatsCountData(response.data);
        setLoading(false);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      setStatsCountData([]);
    }
  };
  return {
    getStatsApi,
    statsData,
    addStatusApi,
    getStatsCountApi,
    statsCountData,
    loading,
  };
};

export default useStatusApi;
