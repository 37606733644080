import { Popover } from "antd";
import React from "react";
import { StatusBarProps } from "./Types";
import { formatDateStatus, statusColor } from "../../../Utils";

const StatusBar: React.FC<{ status: StatusBarProps[] }> = ({ status }) => {
 
  const totalLines = 30;
  const renderLines = (
    count: number,
    status: string,
    date: string,
    first_name: string,
    last_name: string
  ): JSX.Element[] => {
    return Array.from({ length: count }).map((_, index) => (
      <span key={`${index}`}>
        
        {date !== "No Date" ? (
          <Popover
            key={index + 1}
            content={
              <span style={{ display: "flex", flexDirection: "column" }}>
                <span>{`Date: ${formatDateStatus(date)}`}</span>
                <span>{`Notified by: ${first_name} ${last_name} `}</span>
              </span>
            }
            placement="bottom"
          >
            <span
              style={{
                width: "3px",
                height: "1.2rem",
                backgroundColor: `${statusColor(status.toLowerCase())}`,
                display: "inline-block",
                marginRight: "2px",
              }}
            ></span>
          </Popover>
        ) : (
          <span
            key={index + 2}
            style={{
              width: "3px",
              height: "1.2rem",
              backgroundColor: status,
              display: "inline-block",
              marginRight: "2px",
            }}
          ></span>
        )}
      </span>
    ));
  };

  const renderStatusLines = () => {
    let renderedLines = 0;
    const linesToRender = totalLines - status.length;

    const lines: JSX.Element[] = [];
    if (renderedLines < totalLines) {
      lines.push(
        ...renderLines(
          linesToRender,
          "#3F3F3F",
          "No Date",
          "No Data",
          "No Data"
        )
      );
    }

    status.forEach((data, index) => {
      
      const linesCount = 1;

      lines.push(
        <React.Fragment key={`status-${index}`}>
          {renderLines(
            linesCount,
            data.status,
            data.createdAt,
            data.tester.first_name,
            data.tester.last_name
          )}
        </React.Fragment>
      );
      renderedLines += linesCount;
    });
    return lines;
  };

  return <span style={{ marginLeft: "5px" }}>{renderStatusLines()}</span>;
};

export default StatusBar;
