import React, { useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const useLoading = () => {
  const [isLoading, setLoading] = useState(false);

  const showLoading = () => setLoading(true);
  const hideLoading = () => setLoading(false);
 
  const Loading: React.FC = () =>
    isLoading ? (
      <div
        style={{
          zIndex: 1000,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        {/* <Spin size="large" /> */}
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
      </div>
    ) : null;

  return { showLoading, hideLoading, Loading, isLoading };
};

export default useLoading;
