import React, { useState } from "react";
import { MessageOutlined } from "@ant-design/icons";
import AppFooter from "../../Templates/Footer";
import AddStatus from "../Status/AddStatus";
import { HistoryDataGridProps } from "./Types";
import { formatDateStatus, getUserData, statusColor } from "../../Utils";
import ModelComments from "../../Templates/Comments";

const HistoryDataGrid: React.FC<HistoryDataGridProps> = ({
  statusData,
  botOptions,
  onReload,
  loading,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentComments, setCurrentComments] = useState("");
  const { type } = getUserData();
  const handleCommentsClick = (comments: string) => {
    setCurrentComments(comments);
    setModalVisible(true);
  };
  return (
    <React.Fragment>
      <div className="statusNhistoryGrid">
        {statusData.map((data, index) => (
          <div
            key={index}
            className="projectRow_"
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <div className="projectDetails">
              <span
                className="statusCircle"
                style={{
                  backgroundColor: `${statusColor(data.status)}`,
                }}
              ></span>
              <img
                src={data.botIcon}
                alt=""
                width={25}
                height={25}
                className="botIcon"
              />
              <span style={{ fontWeight: 500, fontSize: "18px" }}>
                {data.botTitle}
              </span>
            </div>

            <div
              className="historyStatus"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="historyNotification">
                {data.createdAt
                  ? `( Last notified at ${formatDateStatus(
                      data.createdAt
                    )} by ${data.tester} )`
                  : "Yet to update"}
              </span>
              <span className="grayDot"> </span>
            </div>

            <MessageOutlined
              style={{
                fontSize: "20px",
                padding: "0px 5px 0px 5px",
                color: "#007FFF",
                cursor: "pointer",
              }}
              onClick={() => handleCommentsClick(data.comment)}
            />
          </div>
        ))}
      </div>

      {type === "Tester" && (
        <AddStatus botOptions={botOptions} onReload={onReload} />
      )}
      <AppFooter />
      <ModelComments
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        comments={currentComments}
      />
    </React.Fragment>
  );
};

export default HistoryDataGrid;
