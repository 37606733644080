import React from "react";
import { Modal, Input, Button } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CloseCircleOutlined } from "@ant-design/icons";

interface Delete {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ModelDelete: React.FC<Delete> = ({ visible, onConfirm, onCancel }) => {
  const formik = useFormik({
    initialValues: {
      confirmation: "",
    },
    validationSchema: Yup.object().shape({
      confirmation: Yup.string()
        .oneOf(["DELETE"], 'You must type "DELETE" to confirm.')
        .required('You must type "DELETE" to confirm.'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      onConfirm();
      setSubmitting(false);
      resetForm();
    },
  });

  return (
    <Modal
      title="Confirm Delete"
      centered
      visible={visible}
      // onCancel=
      closeIcon={
        <CloseCircleOutlined
          style={{ fontSize: "25px" }}
          onClick={() => {
            formik.resetForm();
            onCancel();
          }}
        />
      }
      footer={null}
    >
      <form onSubmit={formik.handleSubmit}>
        <p>Please type "DELETE" to confirm this action.</p>
        <Input {...formik.getFieldProps("confirmation")} placeholder="DELETE" />
        {formik.touched.confirmation && formik.errors.confirmation ? (
          <div className="errorMsg">{formik.errors.confirmation}</div>
        ) : null}
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
        >
          <Button
            onClick={() => {
              formik.resetForm();
              onCancel();
            }}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{ border: "1px solid red" }}
            danger
            disabled={!formik.isValid || formik.isSubmitting}
          >
            Delete
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModelDelete;
