import { useState } from "react";
import axios from "axios";
import {
  AddUserType,
  UserDataType,
  UserDeleteType,
} from "../Components/User/Types";
import { getUserData } from "../Utils";

const useUserApis = () => {
  const [testerData, setTesterData] = useState<UserDataType[]>([]);
  const [viewerData, setViewerData] = useState<UserDataType[]>([]);
  const { token } = getUserData();
  const addUserApi = async (data: AddUserType) => {
    const url = `/API/user/addUser`;
    const body = data;
    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const deleteUserApi = async (data: UserDeleteType) => {
    const url = `/API/user/deactivateUser`;

    const body = data;

    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const getTestersApi = async () => {
    const url = `/API/user/getAllTesters`;

    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.data) {
        if (response.data.length > 0) setTesterData(response.data);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      setTesterData([]);
    }
  };

  const getViewersApi = async () => {
    const url = `/API/user/getAllViewers`;

    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.data) {
        if (response.data.length > 0) setViewerData(response.data);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      setViewerData([]);
    }
  };

  const activateUserApi = async (data: UserDeleteType) => {
    const url = `/API/user/activateUser`;
    const body = data;
    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };
  return {
    getTestersApi,
    testerData,
    deleteUserApi,
    addUserApi,
    getViewersApi,
    viewerData,
    activateUserApi,
  };
};

export default useUserApis;
