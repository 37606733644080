import { notification } from "antd";
import { useEffect, useRef } from "react";

const useNotification = () => {
  const notificationRef = useRef<typeof notification>();

  useEffect(() => {
    notificationRef.current = notification;
  }, []);

  const openNotification = (
    message: string,
    description: string,
    type: "error" | "success" | "warning" | "info"
  ) => {
    if (notificationRef.current) {
      notificationRef.current[type]({
        message,
        description,
        placement: "bottomLeft",
      });
    }
  };

  return { openNotification };
};

export default useNotification;
