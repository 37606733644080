import React, { useState } from "react";
import { Button, Input } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import AppFooter from "../../Templates/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLoginApi from "../../Api/LoginApi";
import useLoading from "../../Templates/Loading";
import useNotification from "../../Templates/Alert";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Routes/AuthContext";

const Login: React.FC = () => {
  const { loginApi } = useLoginApi();
  const { Loading, showLoading, hideLoading } = useLoading();
  const { openNotification } = useNotification();
  const navigate = useNavigate();
  const { login } = useAuth();
  // State to toggle password visibility
  const [passwordVisible, setPasswordVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      key: "",
    },
    validationSchema: Yup.object({
      key: Yup.string()
        .min(11, "Key must be 11 characters")
        .required("Enter your key"),
    }),
    onSubmit: async (values) => {
      showLoading();
      const response = await loginApi(values);
      if (response.success) {
        openNotification("Login Success", "", "success");
        setTimeout(() => {
          navigate("/status");
          login()
        }, 1000);
      } else {
        openNotification("Login Failed", "", "error");
      }
      formik.resetForm();
      hideLoading();
    },
  });

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <>
      <div className="login-container">
        <div className="login-box">
          <img
            src="corover.png"
            width={150}
            alt="Logo"
            style={{ margin: "0px 0px 15px 0px" }}
          />
          <div>
            <Input
              variant="filled"
              type={passwordVisible ? "text" : "password"}
              name="key"
              onChange={formik.handleChange}
              value={formik.values.key}
              placeholder="Enter your key"
              style={{ width: "255px" }}
              suffix={
                passwordVisible ? (
                  <EyeInvisibleOutlined
                    onClick={() => setPasswordVisible(false)}
                  />
                ) : (
                  <EyeOutlined onClick={() => setPasswordVisible(true)} />
                )
              }
            />
            {formik.touched.key && formik.errors.key ? (
              <div className="errorMsg">{formik.errors.key}</div>
            ) : null}
          </div>
          <Button
            type="primary"
            style={{ width: "255px" }}
            className="login-button"
            onClick={handleSubmit}
          >
            Login
          </Button>
        </div>
      </div>
      <AppFooter />
      <Loading />
    </>
  );
};

export default Login;
