import React from "react";
import Navigation from "../../Navigation";
import "../../Styles/index.css";
import Tabs from "./Tabs";
import AppFooter from "../../Templates/Footer";

function User() {
  return (
    <>
      <Navigation />
      <Tabs />
      <AppFooter />
    </>
  );
}

export default User;
