import { UploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Button, message, Upload } from "antd";
import { UploadImageProps } from "../Types";

const UploadImage: React.FC<UploadImageProps> = ({ onImageUpload }) => {
  const [loading, setLoading] = useState(false);

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUpload = async ({ file }: any) => {
    setLoading(true);
    try {
      const base64 = await getBase64(file);
      onImageUpload(base64);
    } catch (error) {
      console.error("Error converting file to base64", error);
      message.error("Error uploading image");
    }
    setLoading(false);
  };

  return (
    <Upload
      name="avatar"
      listType="picture"
      beforeUpload={(file) => {
        handleUpload({ file });
        return false;
      }}
      multiple={false}
      maxCount={1}
    >
      <Button
        icon={<UploadOutlined />}
        loading={loading}
        style={{ width: "253px" }}
      >
        Click to Upload
      </Button>
    </Upload>
  );
};

export default UploadImage;
