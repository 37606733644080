import React from "react";
import { Modal, Button, Input } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import UploadImage from "./UploadImage";
import useBotApis from "../../../Api/BotApi";
import useNotification from "../../../Templates/Alert";
import { AddFormProps } from "../Types";
import useLoading from "../../../Templates/Loading";

const AddBotForm: React.FC<AddFormProps> = ({ handleCancel, onReload }) => {
  const { addBotApi } = useBotApis();
  const { openNotification } = useNotification();
  const { showLoading, hideLoading } = useLoading();

  const validationSchema = Yup.object({
    botTitle: Yup.string()
      .matches(
        /^[a-zA-Z]*$/,
        "BotTitle must contain only letters, no spaces or special characters"
      )
      .required("Bot Title is required")
      .min(3, "Bot name must be at least 3 characters")
      .max(20, "Bot name must be at least 20 characters"),
    accessLink: Yup.string()
      .url("Must be a valid URL")
      .required("Access Link is required"),
    botIcon: Yup.string().required("Image upload is required"),
  });

  const handleFormSubmit = async (values: any, { setSubmitting }: any) => {
    const response = await addBotApi(values);
    showLoading();
    setTimeout(() => {
      hideLoading();
      if (response.success) openNotification(response.message, "", "success");
      else if (response.exist)
        openNotification(response.message, "", "warning");
      else openNotification(response.message, "", "error");
    }, 1000);

    if (response) {
      onReload();
    }
    handleCancel();
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ botTitle: "", accessLink: "", botIcon: "" }}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ setFieldValue, isSubmitting, handleSubmit }) => (
        <Modal
          centered
          open={true}
          closeIcon={
            <CloseCircleOutlined
              style={{ fontSize: "25px" }}
              onClick={handleCancel}
            />
          }
          width={300}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px 0px 0px 0px",
              }}
            >
              <Button
                type="primary"
                onClick={() => handleSubmit()}
                style={{
                  color: "#1677ff",
                  border: "1px solid #1677ff",
                  background: "white",
                }}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </div>
          }
        >
          <span style={{ fontSize: "20px", fontWeight: 500 }}>Add Bot</span>
          <Form
            style={{
              padding: "10px 0px 0px 0px",
              gap: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Field name="botTitle">
              {({ field, form, meta }: any) => (
                <div>
                  <Input
                    {...field}
                    placeholder="Bot Title"
                    onChange={(e) => {
                      const value = e.target.value;
                      const capitalizedValue =
                        value.charAt(0).toUpperCase() + value.slice(1);
                      form.setFieldValue(field.name, capitalizedValue);
                    }}
                  />
                  {meta.touched && meta.error ? (
                    <div className="errorMsg">{meta.error}</div>
                  ) : null}
                </div>
              )}
            </Field>

            <Field name="accessLink">
              {({ field, meta }: any) => (
                <div>
                  <Input {...field} placeholder="Access Link" />
                  {meta.touched && meta.error ? (
                    <div className="errorMsg">{meta.error}</div>
                  ) : null}
                </div>
              )}
            </Field>

            <Field name="botIcon">
              {({ field, form }: any) => (
                <div>
                  <UploadImage
                    onImageUpload={(base64: string) => {
                      setFieldValue("botIcon", base64);
                    }}
                  />
                  {form.touched.botIcon && form.errors.botIcon ? (
                    <div className="errorMsg">{form.errors.botIcon}</div>
                  ) : null}
                </div>
              )}
            </Field>

            <span
              style={{ color: "gray", fontSize: "14px", textAlign: "center" }}
            >
              Strictly 512 x 512 pixels.
            </span>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddBotForm;
