import React, { useEffect, useState } from "react";
import {
  SearchOutlined,
  ReloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Input, Dropdown, Menu } from "antd";
import moment from "moment";
import { sortItems, statusItems } from "../../Data";
import useBotApis from "../../Api/BotApi";
import useLoading from "../../Templates/Loading";
import HistoryDataGrid from "./DataGrid";
import useHistoryApi from "../../Api/HistoryApi";
import { HistoryDataType } from "./Types";
import StatusCount from "../Status/StatusTable/StatusCount";
import useStatusApi from "../../Api/StatusApi";

const Options: React.FC = () => {
  const [botsName, setBotsName] = useState("All Bots");
  const [statusLabel, setStatusLabel] = useState("Any Status");
  const [sortLabel, setSortLabel] = useState("Any Sort");
  const [searchTerm, setSearchTerm] = useState("");
  const { botOptionsApi, botOptions } = useBotApis();
  const { getHistoryApi, historyData, loading } = useHistoryApi();
  const [filteredData, setFilteredData] = useState<HistoryDataType[]>([]);
  const [reloadFlag, setReloadFlag] = useState(false);
  const { Loading, showLoading, hideLoading } = useLoading();
  const { getStatsCountApi, statsCountData } = useStatusApi();

  const handleStatusMenuSelect = ({ key }: any) => {
    const selectedLabel = statusItems.find((item) => item.key === key)?.label;
    setStatusLabel(selectedLabel || "Any Status");
  };

  const handleSortMenuSelect = ({ key }: any) => {
    const selectedLabel = sortItems.find((item) => item.key === key)?.label;
    setSortLabel(selectedLabel || "Any Sort");
  };

  // const handleBotsMenuSelect = ({ key }: any) => {
  //   const selectedLabel = botOptions.find((item) => item.key === key)?.label;
  //   setBotsName(selectedLabel || "All Bots");
  // };

  const handleRefresh = async () => {
    showLoading();
    try {
      await getHistoryApi();
      setBotsName("All Bots");
      setStatusLabel("Any Status");
      setSortLabel("Recent Update");
    } finally {
      hideLoading();
    }
  };

  // const sortByBots = (item: any) => {
  //   setSelectedBot(item);
  //   handleBotsMenuSelect(item);
  //   setSearchTerm("");
  // };

  const applyFiltersAndSort = () => {
    let filtered = [...historyData];

    if (statusLabel !== "Any Status") {
      filtered = filtered.filter(
        (data) => data.status.toLowerCase() === statusLabel.toLowerCase()
      );
    }

    if (botsName !== "All Bots") {
      filtered = filtered.filter((data) => data.botTitle === botsName);
    }

    if (searchTerm) {
      filtered = filtered.filter((data) =>
        data.botTitle.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortLabel.toLowerCase() === "name") {
      filtered.sort((a, b) =>
        (a.botTitle || "").localeCompare(b.botTitle || "")
      );
    } else if (sortLabel.toLowerCase() === "recent update") {
      filtered.sort(
        (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
      );
    }

    setFilteredData(filtered);
  };

  // const filteredBots = botOptions.filter((item) =>
  //   item.label.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  useEffect(() => {
    const fetchHistory = async () => {
      showLoading();
      try {
        await botOptionsApi();
        await getHistoryApi();
        await getStatsCountApi();
      } finally {
        hideLoading();
      }
    };

    fetchHistory();
  }, [reloadFlag]);

  useEffect(() => {
    setFilteredData(historyData);
  }, [historyData]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [statusLabel, botsName, sortLabel, historyData, searchTerm]);

  return (
    <>
      <StatusCount statsCountData={statsCountData} />
      <div className="optionsContainer">
        <Input
          className="searchOptions"
          prefix={<SearchOutlined />}
          placeholder="Search Bot"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="buttonGroup">
          <Button
            icon={
              <ReloadOutlined
                style={{ color: "green", fontSize: "15px", fontWeight: "500" }}
              />
            }
            onClick={handleRefresh}
            className="optionButton"
          >
            Refresh
          </Button>
          <Dropdown
            overlay={
              <Menu
                style={{ padding: "5px 5px" }}
                items={statusItems.map((item) => ({
                  key: item.key,
                  label: <div style={{ padding: "5px 5px" }}>{item.label}</div>,
                }))}
                onClick={handleStatusMenuSelect}
              />
            }
            placement="bottom"
            trigger={["click"]}
          >
            <Button
              icon={<DownOutlined />}
              className="optionButton"
              style={{ padding: "0px 20px" }}
            >
              <span style={{ padding: "0px 10px" }}> {statusLabel}</span>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={
              <Menu
                items={sortItems.map((item) => ({
                  key: item.key,
                  label: <div style={{ padding: "5px 5px" }}>{item.label}</div>,
                }))}
                onClick={handleSortMenuSelect}
              />
            }
            placement="bottom"
            trigger={["click"]}
          >
            <Button icon={<DownOutlined />} className="optionButton">
              {sortLabel}
            </Button>
          </Dropdown>
        </div>
      </div>
      <Loading />

      {filteredData.length === 0 ? (
        <span className="gridNotFound">No bots to display.</span>
      ) : (
        <HistoryDataGrid
          statusData={filteredData}
          botOptions={botOptions}
          onReload={() => setReloadFlag(!reloadFlag)}
          loading={loading}
        />
      )}
    </>
  );
};

export default Options;
