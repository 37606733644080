import React from "react";
import { Layout } from "antd";
import "./index.css";

const { Footer } = Layout;
const currentYear = new Date().getFullYear();

const AppFooter: React.FC = () => (
  <Footer className="footer-container">
    <a href="https://corover.ai/" target="_blank" rel="noopener noreferrer">
      © {currentYear} CoRover Private Limited.
    </a>
  </Footer>
);

export default AppFooter;
