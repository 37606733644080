import React from "react";
import Navigation from "../../Navigation";
import BotDataGrid from "./DataDrid";
import AppFooter from "../../Templates/Footer";

const Bot: React.FC = () => (
  <>
    <Navigation />
    <BotDataGrid />
    <AppFooter />
  </>
);

export default Bot;
