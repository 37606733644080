import moment from "moment";

export const capitalize = (sentence: string) => {
  if (!sentence) return sentence;
  let words = sentence.split(" ");
  words.forEach((word, index) => {
    words[index] = word.charAt(0).toUpperCase() + word.slice(1);
  });
  return words.join(" ");
};

export const formatDateStatus = (dateString: string) => {
  const date = moment(dateString).subtract(6, "hours").add(30, "minutes");
  const isToday = date.isSame(moment(), "day");
  const isYesterday = date.isSame(moment().subtract(1, "day"), "day");

  if (isToday) {
    return date.format("hh:mm A") + " today";
  } else if (isYesterday) {
    return date.format("hh:mm A") + " yesterday";
  } else {
    return date.format("hh:mm A DD-MM-YYYY");
  }
};

export const statusColor = (status: string) => {
  if (!status) return "gray";
  switch (status.toLowerCase()) {
    case "up":
      return "#00A023";
    case "partially working":
      return "#F37A0B";
    case "slow performance":
      return "#F5CE00";
    case "under maintenance":
      return "#0085FF";
    case "down":
      return "#AF0000";
    default:
      return "gray";
  }
};

export const getUserData = () => {
  let userData;
  const data = localStorage.getItem("user");

  if (data) {
    userData = JSON.parse(data);
  } else {
    userData = {
      success: "",
      name: "",
      id: "",
      token: "",
      type: "",
    };
  }
  return userData;
};
