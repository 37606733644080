import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import AddUserForm from "./AddUserForm";
import { AddFormIndexProps } from "../../Bot/Types";

const AddUser: React.FC<AddFormIndexProps> = ({ onReload }) => {
  const [model, setModel] = React.useState<boolean>(false);

  return (
    <>
      {!model ? (
        <FloatButton
          icon={<PlusOutlined style={{ color: "white" }} />}
          type="default"
          onClick={() => setModel(true)}
          style={{
            right: "5%",
            bottom: "10%",
            backgroundColor: "black",
            boxShadow: "4px 3px 5px rgba(0, 0, 0, 0.5)",

          }}
        />
      ) : (
        <>
          <AddUserForm
            handleCancel={() => setModel(false)}
            onReload={onReload}
          />
        </>
      )}
    </>
  );
};
export default AddUser;
