import { useState } from "react";
import axios from "axios";
import { addBotApiType, BotDataType } from "../Components/Bot/Types";
import { DropDownType } from "../Components/Status/StatusTable/Types";
import useLoading from "../Templates/Loading";
import { getUserData } from "../Utils";

const useBotApis = () => {
  const [botsData, setBotsData] = useState<BotDataType[]>([]);
  const [deleteData, setDeleteData] = useState({
    message: "",
    success: false,
  });
  let [botOptions, setBotOptions] = useState<DropDownType[]>([]);
  const { showLoading, hideLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const { token } = getUserData();

  const addBotApi = async (data: addBotApiType) => {
    showLoading();
    setLoading(true);
    const url = `/API/bot/addBot`;

    const body = data;

    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.data) {
        hideLoading();
        setLoading(false);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      setBotsData([]);
    }
  };

  const getBotsApi = async () => {
    showLoading();
    setLoading(true);
    const url = `/API/bot/getBots`;

    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.data) {
        hideLoading();
        setLoading(false);
        setBotsData(response.data);
      } else setBotsData([]);
    } catch (error) {
      console.error("Error:", error);
      setBotsData([]);
    }
  };

  const deleteBotApi = async (id: string) => {
    const url = `/API/bot/deactivateBot/${id}`;

    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.data) {
        setDeleteData(response.data);
        return response.data;
      } else {
        setDeleteData({
          message: "",
          success: false,
        });
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const botOptionsApi = async () => {
    const url = `/API/bot/getBotNames`;

    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.data.length > 0) {
        const existingBotNames = new Set(botOptions.map((bot) => bot.label));
        const newBots = response.data
          .filter((data: any) => !existingBotNames.has(data.label))
          .map((data: any) => ({
            key: data.key,
            label: data.label,
          }));

        setBotOptions((prevBotOptions) => [...prevBotOptions, ...newBots]);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      setBotOptions([]);
    }
  };

  return {
    setDeleteData,
    botsData,
    getBotsApi,
    deleteBotApi,
    deleteData,
    addBotApi,
    botOptionsApi,
    botOptions,
    loading,
  };
};

export default useBotApis;
