import { useState } from "react";
import axios from "axios";
import { HistoryDataType } from "../Components/History/Types";
import useLoading from "../Templates/Loading";
import { getUserData } from "../Utils";

const useHistoryApi = () => {
  const [historyData, setHistoryData] = useState<HistoryDataType[]>([]);
  const { showLoading, hideLoading } = useLoading();
  const { token } = getUserData();
  const [loading, setLoading] = useState(false);

  const getHistoryApi = async () => {
    showLoading();
    setLoading(true);
    const url = `/API/getHistory`;
    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data) {
        if (response.data.length > 0) {
          setHistoryData(response.data);
          hideLoading();
          setLoading(false);
        }
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      setHistoryData([]);
    }
  };

  return { getHistoryApi, historyData, loading };
};

export default useHistoryApi;
