import React from "react";
import { Modal, Button, Dropdown, Input, Menu } from "antd";
import { DownOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import useUserApis from "../../../Api/UserApi";
import useLoading from "../../../Templates/Loading";
import useNotification from "../../../Templates/Alert";
import { AddFormProps } from "../../Bot/Types";
import { userOptions } from "../../../Data";

const AddUserForm: React.FC<AddFormProps> = ({ handleCancel, onReload }) => {
  const { addUserApi } = useUserApis();
  const { openNotification } = useNotification();
  const { showLoading, hideLoading } = useLoading();

  const formik = useFormik({
    initialValues: {
      type: "",
      first_name: "",
      last_name: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Type is required"),
      first_name: Yup.string()
        .matches(
          /^[a-zA-Z]*$/,
          "First name must contain only letters, no spaces or special characters"
        )
        .min(3, "First name must be at least 4 characters")
        .max(15, "First name must be at most 15 characters")
        .required("First name is required"),
      last_name: Yup.string()
        .min(1, "Last name must be at least 1 character")
        .matches(
          /^[a-zA-Z]*$/,
          "Last name must contain only letters, no spaces or special characters"
        )
        .max(10, "Last name must be at most 10 characters")
        .required("Last name is required"),
    }),
    onSubmit: async (values) => {
      const response = await addUserApi(values);
      setTimeout(() => {
        hideLoading();
        if (response.success)
          openNotification(
            `User is added successfully, click on "Copy Key" to get the login access key`,
            "",
            "success"
          );
        else if (response.exists)
          openNotification(response.message, "", "warning");
        else openNotification(response.message, "", "error");
      }, 1000);
      handleCancel();
      onReload();
    },
  });
  const capitalizeFirstLetter = (data: string) => {
    return data.charAt(0).toUpperCase() + data.slice(1);
  };

  const handleTypeMenuSelect = ({ key }: any) => {
    const selectedItem = userOptions.find((item) => item.key === key)?.label;
    formik.setFieldValue("type", selectedItem || "");
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const handleChangeAndCapitalize = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, capitalizeFirstLetter(value));
  };

  return (
    <Modal
      centered
      open={true}
      closeIcon={
        <CloseCircleOutlined
          style={{ fontSize: "25px" }}
          onClick={handleCancel}
        />
      }
      width={300}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px 0px 0px 0px",
          }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{
              color: "#1677ff",
              border: "1px solid #1677ff",
              background: "white",
            }}
          >
            Submit
          </Button>
        </div>
      }
    >
      <span style={{ fontSize: "20px", fontWeight: 500 }}>Add User</span>

      <span
        style={{
          padding: "10px 0px 0px 0px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <Dropdown
            overlay={
              <Menu onClick={handleTypeMenuSelect}>
                {userOptions.map((item) => (
                  <Menu.Item key={item.key} style={{ padding: "8px 5px" }}>
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottom"
            trigger={["click"]}
          >
            <Button
              icon={<DownOutlined />}
              style={{
                width: "100%",
                color: "gray",
                fontSize: "16px",
                fontWeight: 400,
                justifyContent: "left",
              }}
            >
              {formik.values.type || "Select Type"}
            </Button>
          </Dropdown>
          {formik.touched.type && formik.errors.type ? (
            <span className="errorMsg">{formik.errors.type}</span>
          ) : null}
        </div>
        <div>
          <Input
            placeholder="First Name"
            name="first_name"
            value={formik.values.first_name}
            onChange={handleChangeAndCapitalize}
            onBlur={formik.handleBlur}
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <span className="errorMsg">{formik.errors.first_name}</span>
          ) : null}
        </div>
        <div>
          <Input
            placeholder="Last Name"
            name="last_name"
            value={formik.values.last_name}
            onChange={handleChangeAndCapitalize}
            onBlur={formik.handleBlur}
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <span className="errorMsg">{formik.errors.last_name}</span>
          ) : null}
        </div>
      </span>
    </Modal>
  );
};

export default AddUserForm;
