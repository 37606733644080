import React from "react";
import { Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

interface CommentsModalProps {
  visible: boolean;
  onCancel: () => void;
  comments: string;
}

const ModelComments: React.FC<CommentsModalProps> = ({
  visible,
  onCancel,
  comments,
}) => {
  return (
    <Modal
      title="Comments"
      open={visible}
      closeIcon={
        <CloseCircleOutlined style={{ fontSize: "25px" }} onClick={onCancel} />
      }
      footer={null}
    >
      <p>{comments ? comments : "Yet to update"} </p>
    </Modal>
  );
};

export default ModelComments;
