import React, { useEffect, useState } from "react";
import {
  SearchOutlined,
  ReloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Input, Dropdown, Menu } from "antd";
import moment from "moment";
import StatusDataGrid from "./DataGrid";
import { StatusDataType } from "./Types";
import { sortItems, statusItems } from "../../../Data";
import useBotApis from "../../../Api/BotApi";
import useStatusApi from "../../../Api/StatusApi";
import useLoading from "../../../Templates/Loading";
import StatusCount from "./StatusCount";

const Options: React.FC = () => {
  const [botsName, setBotsName] = useState("All Bots");
  const [statusLabel, setStatusLabel] = useState("Any Status");
  const [sortLabel, setSortLabel] = useState("Recent Update");
  const [searchTerm, setSearchTerm] = useState("");
  const { botOptionsApi, botOptions } = useBotApis();
  const { getStatsApi, statsData, getStatsCountApi, statsCountData, loading } =
    useStatusApi();
  const [filteredData, setFilteredData] = useState<StatusDataType[]>([]);
  const [reloadFlag, setReloadFlag] = useState(false);
  const { Loading, showLoading, hideLoading, isLoading } = useLoading();

  const handleStatusMenuSelect = ({ key }: any) => {
    const selectedLabel = statusItems.find((item) => item.key === key)?.label;
    setStatusLabel(selectedLabel || "Any Status");
  };

  const handleSortMenuSelect = ({ key }: any) => {
    const selectedLabel = sortItems.find((item) => item.key === key)?.label;
    setSortLabel(selectedLabel || "Recent Update");
  };

  const handleRefresh = async () => {
    showLoading();
    try {
      await getStatsApi();
      setBotsName("All Bots");
      setStatusLabel("Any Status");
      setSortLabel("Recent Update");
    } finally {
      hideLoading();
    }
  };

  const applyFiltersAndSort = () => {
    let filtered = [...statsData];

    // Filter by status
    if (statusLabel !== "Any Status") {
      filtered = filtered.filter(
        (data) =>
          data.status && data.status.toLowerCase() === statusLabel.toLowerCase()
      );
    }

    if (botsName !== "All Bots") {
      filtered = filtered.filter((data) => data.project === botsName);
    }

    if (searchTerm) {
      filtered = filtered.filter((data) =>
        data.project.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortLabel.toLowerCase() === "name") {
      filtered.sort((a, b) => (a.project || "").localeCompare(b.project || ""));
    } else if (sortLabel.toLowerCase() === "recent update") {
      filtered.sort(
        (a, b) => moment(b.lastSeen).valueOf() - moment(a.lastSeen).valueOf()
      );
    }

    setFilteredData(filtered);
  };

  useEffect(() => {
    const fetchStats = async () => {
      showLoading();
      try {
        await botOptionsApi();
        await getStatsApi();
        await getStatsCountApi();
      } finally {
        hideLoading();
      }
    };

    fetchStats();
  }, [reloadFlag]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [statusLabel, botsName, sortLabel, searchTerm, statsData]);

  return (
    <>
      <StatusCount statsCountData={statsCountData} />
      <div className="optionsContainer">
        <Input
          className="searchOptions"
          prefix={<SearchOutlined />}
          placeholder="Search Bot"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="buttonGroup">
          <Button
            icon={
              <ReloadOutlined
                style={{ color: "green", fontSize: "15px", fontWeight: "500" }}
              />
            }
            onClick={handleRefresh}
            className="optionButton"
          >
            Refresh
          </Button>
          <Dropdown
            overlay={
              <Menu onClick={handleStatusMenuSelect}>
                {statusItems.map((item) => (
                  <Menu.Item key={item.key} style={{ padding: "5px 5px" }}>
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottom"
            trigger={["click"]}
          >
            <Button
              icon={<DownOutlined />}
              className="optionButton"
              style={{ padding: "0px 20px" }}
            >
              <span style={{ padding: "0px 10px" }}> {statusLabel}</span>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={
              <Menu onClick={handleSortMenuSelect}>
                {sortItems.map((item) => (
                  <Menu.Item key={item.key} style={{ padding: "5px 5px" }}>
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottom"
            trigger={["click"]}
          >
            <Button icon={<DownOutlined />} className="optionButton">
              {sortLabel}
            </Button>
          </Dropdown>
        </div>
      </div>
      <Loading />
      {!isLoading && filteredData.length === 0 ? (
        <span className="gridNotFound">No bots to display.</span>
      ) : (
        <StatusDataGrid
          statusData={filteredData}
          botOptions={botOptions}
          onReload={() => setReloadFlag(!reloadFlag)}
          loading={loading}
        />
      )}
    </>
  );
};

export default Options;
