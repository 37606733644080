import React from "react";
import AppFooter from "../../../Templates/Footer";
import Options from "./Options";
import Navigation from "../../../Navigation";

const StatusTable: React.FC = () => (
  <>
    <Navigation />
    <Options />
    <AppFooter />
  </>
);

export default StatusTable;
