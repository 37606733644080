import React, { useState } from "react";
import { Radio } from "antd";
import Viewers from "./Users/Viewers";
import Testers from "./Users/Testers";

const Tabs: React.FC = () => {
  const [userType, setUserType] = useState<String>("testers");
  return (
    <div>
      <div
        className="userTabsContainer"
       
      >
        <Radio.Group
          value={userType}
          onChange={(e) => setUserType(e.target.value)}
          buttonStyle="solid"
        >
          <Radio.Button value="viewers">Viewers</Radio.Button>
          <Radio.Button value="testers">Testers</Radio.Button>
        </Radio.Group>
      </div>
      {userType === "viewers" ? <Viewers /> : <Testers />}
    </div>
  );
};

export default Tabs;
