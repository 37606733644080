import { DropDownType } from "../Components/Status/StatusTable/Types";

export const statusItems: DropDownType[] = [
  { key: "0", label: "Any Status" },
  { key: "1", label: "Up" },
  { key: "2", label: "Partially Working" },
  { key: "3", label: "Slow Performance" },
  { key: "4", label: "Under Maintenance" },
  { key: "5", label: "Down" },
];

export const sortItems: DropDownType[] = [
  { key: "1", label: "Name" },
  { key: "2", label: "Recent Update" },
];

export const userOptions: DropDownType[] = [
  {
    key: "1",
    label: "Tester",
  },
  {
    key: "2",
    label: "Viewer",
  },
];
