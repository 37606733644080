import React from "react";
import { Modal, Input, Button } from "antd";

interface Delete {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ModelLogout: React.FC<Delete> = ({ visible, onConfirm, onCancel }) => {
  return (
    <Modal
      
      centered
      open={visible}
      closeIcon={false}
      footer={null}
    >
       <p style={{fontSize:"17px"}}>Are you sure you want to logout?</p>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
      >
       
        <Button
          onClick={() => {
            onCancel();
          }}
          style={{ marginRight: 8 }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          style={{ border: "1px solid red" }}
          danger
          onClick={() => {
            onConfirm();
          
          }}
        >
          Logout
        </Button>
      </div>
    </Modal>
  );
};

export default ModelLogout;
